window.$ = require('jquery');
window.jQuery = window.$;

var fbShare = function(url, title, descr, image, winWidth, winHeight)
{
    var winTop = (screen.height / 2) - (winHeight / 2);
    var winLeft = (screen.width / 2) - (winWidth / 2);
    window.open('http://www.facebook.com/sharer.php?s=100&p[title]=' + title + '&p[summary]=' + descr + '&p[url]=' + url + '&p[images][0]=' + image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
}

$('.js-facebook').on('click', function(e) {
    e.preventDefault();

    var _this = $(this);
    var url = _this.attr('href');
    var title = _this.attr('data-title');
    var desc = _this.attr('data-desc');
    var image = _this.attr('data-image');

    fbShare(url, title, desc, image, 520, 350);
});