$(function () {
	
	$(".mobile-menu-nav").click(function() {
        $('.mobile-menu').toggleClass('mobile-menu__open');
        $('body').toggleClass('locked');
	});

});

function toggleSubMenu(element)
{
    if ( subActive.classList.contains('visible') ) {
        subActive.classList.remove('visible');
    } else {
        element.classList.add('visible');
    }
}

let btn = document.querySelectorAll('#mobile-menu__sub-btn');
let submenu = document.querySelectorAll('#sub-menu');
let subActive;

for (var i = 0, len = btn.length; i < len; i++) {
    btn[i].onclick = function (e){
        e.preventDefault();

        var buttonClasses = $(this).data('id');
        var submenus = $('.mobile-menu__sub').filter(function(i, element){
            if(buttonClasses === $(element).data('id')) {
                subActive = element;
                toggleSubMenu(element);
            }
        });
    }
};

let subCloseBtn = document.querySelectorAll('#sub-menu-item');

for (var i = 0, len = subCloseBtn.length; i < len; i++) {
    subCloseBtn[i].onclick = function (){
            $('.mobile-menu').removeClass('mobile-menu__open');
            var target = $(this.hash);
            var lastScrollTop = 0;
        
            $('html, body').animate({
                'scrollTop': target.offset().top - 70
            }, 400);
    }
}