module.exports = new function sidebarLinkAnchors () {

    var sidebar = $('.js-sticky-sidebar');
    var sidebarLinks = $('.sidebar__links li a');
    var article = $('.js-article-inner');

    if ( sidebar.length > 0 ) {

        $(window).on('scroll', function()
        {
            var windowTop = $(this).scrollTop();



            if ( windowTop >= sidebar.offset().top )
            {
                sidebar.addClass('sidebar__inner--sticky');

                // We need to position the sidebar according to the element
                var screenWidth = $(window).width();
                var horizontalPosition = $('.page-container').first().width();

                sidebar.css({
                	'right': ((screenWidth - horizontalPosition) / 2) + 'px'
                });
            }
            else if ( windowTop < $('.js-sidebar').offset().top )
            {
                sidebar.removeClass('sidebar__inner--sticky');
                sidebar.css({
                	'right': '0px'
                });
            }

            article.each(function(i, obj)
            {
                var thisArticle = $(this).offset().top;
                var articleID = $(this).prop('id');

                if ( windowTop >= thisArticle - 130)
                {
                    sidebarLinks.removeClass('js-active-sidebar');
                    $('.sidebar__links li a[href="#' + articleID + '"]').addClass('js-active-sidebar');
                }
            });
        });

    }


    sidebarLinks.on('click', function(e)
    {
        e.preventDefault();
        var target = $(this.hash);
        var lastScrollTop = 0;

        $('html, body').animate({
            'scrollTop': target.offset().top - 70
        }, 400);
    });

}