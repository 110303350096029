$('.top-anchor').click(function(){
    $('html, body').animate({
        scrollTop: $('html').offset().top
    }, 500);
    return false;
});

//Anchor link displays the top anchor.
if($('.follow-scroll').length > 0)
{
    $(window).scroll(function()
    {
        if ($(this).scrollTop() > 200) {
            $('.follow-scroll').addClass('visible');
        } else {
            $('.follow-scroll').removeClass('visible');
        }
    });
}